import { kebabCase, max, min, upperFirst } from "lodash";
import { computed } from "vue";
import type { Service, ServiceGroup } from "~/models/service";
import { ServicePricingType } from "~/services/api-data/types";

/**
 * Display minutes in e.g. `15min`, `1h`, `2h 30min`
 * @param minutes
 * @returns
 */
export const formatMinutes = (minutes: number) => {
  const s = minutes * 60;
  const h = Math.floor(s / 3600);
  const m = Math.floor((s % 3600) / 60) + "min";
  if (h) {
    return `${h}h ${m}`;
  }
  return m;
};

export const useService = (service: Service) => {
  const multiVariants = computed(() => service.variants?.length > 1);

  const duration = computed(() => {
    const d = service.variants.map((variant) => variant.duration);
    if (multiVariants.value) {
      const from = formatMinutes(min(d) || 0);
      const to = formatMinutes(max(d) || 0);
      return `${from} - ${to}`;
    }
    return formatMinutes(max(d) || 0);
  });

  const startingPrice = computed(() => {
    const p = service.variants.map((variant) => variant.price);
    return min(p);
  });

  const gender = computed(() =>
    service.gender ? upperFirst(service.gender.toLocaleLowerCase()) : undefined
  );

  const age = computed(() => service.age_restriction);

  return {
    duration,
    multiVariants,
    startingPrice,
    gender,
    age,
  };
};

export const clientTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const groupServices = (
  services: Service[]
): {
  priced: boolean;
  hasMedia: boolean;
  groups: ServiceGroup[];
} => {
  const group: Record<
    string,
    ServiceGroup & { handle: string; services: Service[] }
  > = {};
  let priced = false;
  let hasMedia = false;

  services.forEach((s) => {
    if (!group[s.group.id]) {
      group[s.group.id] = {
        handle: kebabCase(s.name),
        services: [],
        ...s.group,
      };
    }
    if (!hasMedia) {
      hasMedia = !!s.image || !!s.variants.find((v) => !!v.image);
    }
    if (!priced) {
      priced = !!s.variants.find(
        (v) => v.pricing_type == ServicePricingType.PRICING
      );
    }
    group[s.group.id].services.push(s);
  });

  return {
    priced,
    hasMedia,
    groups: Object.values(group),
  };
};
