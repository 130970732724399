export enum ServiceKind {
  STANDARD = "standard",
  MEETING = "meeting",
  PACKAGE = "package",
}

export enum BookingConfirmation {
  AUTOMATIC = "automatic",
  MANUAL = "manual",
  OPTIMISTIC = "optimistic",
}

export enum ServicePricingType {
  PRICING = "PRICING",
  DESCRIPTION = "DESCRIPTION",
  NONE = "NONE",
}
